import { Box, Grid, Typography } from "@mui/material";
import { DarkButtonWithIcon } from "components/controls/Buttons";
import React from "react";
import { ReactComponent as JoinTheCommunity } from "assets/svg/JoinTheCommunity.svg";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  AuthenticatedPath,
  UnauthenticatedPath,
  getProfilePath,
} from "utility/AppRoutingPath";
import { Info } from "@mui/icons-material";
import PopperComponents from "components/customisedComponents/PopperComponents";
import HttpTransferService from "services/httptransfer";
import MusicTable from "./MusicTable";

type Props = {};

const httptransfer = new HttpTransferService();

const HomepageHero = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);

  const handleOpenPopper = (event: any) => {
    if (anchorEl == event.currentTarget) {
      setOpenPopper(!openPopper);
    } else {
      setAnchorEl(event.currentTarget);
      setOpenPopper(true);
    }
  };

  const canBeOpen = Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const authContext = React.useContext(AuthContext);
  const nav = useNavigate();

  const handleClick = () => {
    if (authContext?.isAuthenticated()) {
      const userInfoString = localStorage.getItem("userInfo");
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
      const profile_unique_id = userInfo?.username;
      const profilePath = getProfilePath(profile_unique_id);

      nav(profilePath);
    } else {
      nav(UnauthenticatedPath.Signup);
    }
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        position="relative"
        height="90vh"
      >
        <Grid
          item
          container
          alignItems="flex-start"
          justifyContent={"flex-start"}
          direction="column"
          color="white"
        >
          <Typography
            sx={{
              "@media (min-width: 1200px)": {},
              fontSize: ["2.7rem", "3.8rem", "5.5rem", "7rem", "8.2129rem"],
              fontFamily: "'Darker Grotesque', sans-serif",
              fontWeight: "700",
              lineHeight: "100%",
              letterSpacing: "-0.03em",
            }}
          >
            EARN{" "}
            <span
              style={{
                color: "#FA6B05",
                fontFamily: "'Darker Grotesque', sans-serif",
                position: "relative",
              }}
            >
              100% REVENUE
            </span>
            <br />
            ON YOUR MUSIC
            {/* <Box
              sx={{
                display: "inline-flex",
                color: "#fff",
                borderRadius: "22px",
                backgroundColor: "#ffffff1f",
                ml: 0.8,
                p: "4px",
                position: "relative",
                bottom: "3px",
                width: "fit-content",
                height: "fit-content",
                userSelect: "none",
                "& svg": {
                  width: ["0.75rem", "1rem"],
                  height: ["0.75rem", "1rem"],
                  pointerEvents: "none",
                },
                cursor: "pointer",
                zIndex: 1000,
              }}
              onMouseEnter={handleOpenPopper}
              onMouseLeave={() => setOpenPopper(false)}
              onClick={e => {
                if (openPopper) {
                  setOpenPopper(false);
                } else {
                  handleOpenPopper(e);
                }
              }}
            >
              <Info />
            </Box> */}
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              fontSize: "1.5rem",
              fontWeight: 500,
              mt: "2rem",
            }}
          >
            Recently added songs
          </Typography>

          <Grid item container xs={12} sx={{ overflow: "hidden", mt: "1rem" }}>
            <MusicTable />
          </Grid>
        </Grid>
      </Grid>

      {/* <PopperComponents
        popperProps={{
          sx: {
            maxWidth: "200px",
            mt: "1rem",
            zIndex: 9999,
            transition: "unset !important",
          },
          placement: "top-end",
        }}
        anchorEl={anchorEl}
        placement="bottom-end"
        id={id}
        open={openPopper}
        setOpen={setOpenPopper}
        key={212}
      >
        <div>Earn 100% revenue for life on music uploaded before 31.12.23</div>
      </PopperComponents> */}
    </>
  );
};

export default HomepageHero;
