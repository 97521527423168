import React, { useContext, useEffect, useState } from "react";
import { cartListingType } from "model/cartListingType";
import { AlertContext } from "./AlertContext";
import HttpTransferService from "services/httptransfer";

import { severityType } from "model/errorSeverityTypeEnum";

const httptransfer = new HttpTransferService();

interface IMusicStoreContext {
  cart: any;
  setCart: (data: cartListingType[]) => void;
  addToCart: (data: any) => void;
  removeFromCart: (data: any) => void;
  clearCart: () => void;
  cartAmount: number;
  Payment: () => void;
  musics: any;
  Albums: any;
  purchsedItems: any;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const MusicStoreContext = React.createContext<IMusicStoreContext | null>(
  null
);

const MusicStoreContextProvider: React.FC<Props> = props => {
  const [cart, setCart] = useState<any[]>([]);
  const [cartAmount, setCartAmount] = useState<number>(0);
  const [musics, setMusics] = useState<any>({ musicData: [] });
  const [Albums, setAlbums] = useState<any>({ albumData: [] });
  const [purchsedItems, setPurchasedItems] = useState<any[]>([]);

  const alertContext = useContext(AlertContext);
  const userInfoString = localStorage.getItem("userInfo");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

  const errorCB = (err: any) => {
    console.log("errorCB", err);
  };

  const fetchCartData = async () => {
    setCart([]);
    const purchaseSuccessCB = async (res: any) => {
      setPurchasedItems(res?.data?.purchased_items);

      const successCB = async (res: any) => {
        const musicIDs = res.data?.user_cart?.music;
        const albumIDs = res.data?.user_cart?.album;

        console.log(res);

        const musicSuccessCallback = (res: any) => {
          const musics = res.data.musics.filter((item: any) => item._id);
          setMusics({ musicData: musics });
          setCart(prevData => [
            ...prevData,
            ...musics.map((music: any) => ({ ...music, type: "music" })),
          ]);
        };

        const albumSuccessCallback = (res: any) => {
          console.log("albumSuccessCallback", res);
          setAlbums({ albumData: res.data.albums });
          setCart(prevData => [
            ...prevData,
            ...res.data.albums.map((album: any) => ({
              ...album,
              type: "album",
            })),
          ]);
        };

        if (musicIDs?.length > 0) {
          await httptransfer.musicQuery(
            { user_id: [userInfo?._id], music_id: musicIDs },
            musicSuccessCallback,
            errorCB
          );
        } else {
          setMusics({ musicData: [] });
        }

        if (albumIDs?.length > 0) {
          await httptransfer.queryAlbum(
            { user_id: [userInfo?._id], album_id: albumIDs },
            albumSuccessCallback,
            errorCB
          );
        } else {
          setAlbums({ albumData: [] });
        }
      };
      await httptransfer.cartQuery(successCB, errorCB);
    };
    await httptransfer.purchasedTracks(purchaseSuccessCB, errorCB);
  };

  const addToCart = async (params: any) => {
    const successCB = async (res: any) => {
      await fetchCartData();
      alertContext?.OpenError({
        title: "Added to Cart",
        message: res.data.message,
        severity: severityType.success,
      });
    };
    const errorCB = (err: any) => {
      alertContext?.OpenError({
        title: "Error",
        message: err.response.data.message,
        severity: severityType.error,
      });
    };

    if (params.music_id) {
      await httptransfer.addIntoCart(
        { music_id: params.music_id },
        successCB,
        errorCB
      );
    } else if (params.album_id) {
      await httptransfer.addIntoCart(
        { album_id: params.album_id },
        successCB,
        errorCB
      );
    }
  };

  const removeFromCart = (data: any) => {
    const removeSuccessCB = (res: any) => {
      fetchCartData();
    };
    httptransfer.removeFromCart(data, removeSuccessCB, errorCB);
  };

  const addAllPriceOfTracks = () => {
    let sum = 0;
    cart.forEach(item => {
      return (sum += parseFloat(item.price.toString()));
    });
    return sum;
  };

  const clearCart = () => {
    setCart([]);
  };

  const Payment = async () => {
    const successCB = (res: any) => {
      //window.open(res.data.url, "target")?.focus();
      window.location.href = res.data.url;
      console.log("successCB", res);
    };

    const errorCB = (err: any) => {
      console.log("errorCB", err);
    };

    const filteredData = cart.filter(item => typeof item === "object");

    await httptransfer.paymentCheckout(
      {
        items: filteredData.map(item => {
          return {
            type: item.type,
            id: item._id,
            name: item.name,
            price: item.price,
          };
        }),
      },
      successCB,
      errorCB
    );
  };

  useEffect(() => {
    //setCartAmount(addAllPriceOfTracks());
    fetchCartData();
    //console.log("musicAndAlbumCartData ", musicAndAlbumCartData);
    console.log("cartData ", cart, addAllPriceOfTracks());
  }, []);

  return (
    <MusicStoreContext.Provider
      value={{
        cart,
        setCart,
        addToCart,
        removeFromCart,
        clearCart,
        cartAmount,
        Payment,
        musics,
        Albums,
        purchsedItems,
      }}
    >
      {props.children}
    </MusicStoreContext.Provider>
  );
};

export default MusicStoreContextProvider;
